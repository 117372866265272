<template>
  <v-container fluid>

    <v-row>
      <v-col
          class="mb-5"
          cols="auto"
          md="12"
          sm="12"
      >
        <v-card>
          <v-data-table
              :footer-props="{
                pageText: '{0}-{1} od {2}',
                itemsPerPageText: 'Korisnika po strani'
              }"
              :headers="headers"
              :items="users"
              :search="searchString"
              class="elevation-1"
              multi-sort
          >
            <template v-slot:item.created_at="{ item }">
              <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>

            <template v-slot:top>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Svi korisnici</v-toolbar-title>

                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>

                <v-text-field
                    v-model="searchString"
                    append-icon="mdi-magnify"
                    hide-details
                    label="Pretraga (ime, prezime, email)"
                    single-line
                ></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog
                    v-model="dialog"
                    max-width="700px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mb-2"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                      Novi korisnik
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ formTitle }}
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-form
                            ref="form"
                            v-model="formValid">
                          <v-row>
                            <v-col cols="12" hidden md="6" sm="12">
                              <v-text-field
                                  v-model="editedItem.id"
                                  label="Id"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.name"
                                  :rules="fieldRules.personalNameRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Ime i prezime"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.email"
                                  :rules="fieldRules.emailRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Email"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.username"
                                  :rules="fieldRules.usernameRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Korisničko ime"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.password"
                                  :required="editedItem.id === null"
                                  :rules="editedItem.id === null ? fieldRules.passwordRules.concat(fieldRules.max50Chars) : []"
                                  clearable
                                  counter="50"
                                  label="Lozinka"
                                  type="password"
                              ></v-text-field>
                            </v-col>
                            <v-col v-if="loggedUserRoleId === 1" cols="12" md="12" sm="12">
                              <v-select
                                  v-model="editedItem.company_id"
                                  :items="companies"
                                  :rules="fieldRules.companyRules"
                                  item-text="name"
                                  item-value="id"
                                  label="Kompanija"
                                  name="role_id"
                                  required
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-select
                                  v-model="editedItem.role_id"
                                  :items="roles"
                                  :rules="fieldRules.roleRules"
                                  item-text="name"
                                  item-value="id"
                                  label="Uloga"
                                  name="role_id"
                                  required
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="red darken-1 font-weight-bold"
                          text
                          @click="close"
                      >
                        Zatvori
                      </v-btn>
                      <v-btn
                          :disabled="!enableSave"
                          color="info font-weight-bold"
                          text
                          @click="validateForm"
                      >
                        Sačuvaj
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      small
                      v-bind="attrs"
                      @click="editItem(item)"
                      v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Izmijeni</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      color="red darken-3"
                      small
                      v-bind="attrs"
                      @click="showDeleteUserModal(item)"
                      v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Izbriši</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-dialog
          v-model="deleteUserModal"
          max-width="650"
      >
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            Brisanje korisnika
          </v-card-title>
          <v-card-text>
            <v-container>

              <p>
                Da li ste sigurni da želite da izbrišete korisnika <b>{{ userName }}</b>?
              </p>

            </v-container>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1 font-weight-bold"
                text
                @click="closeDeleteUserModal"
            >
              Ne želim
            </v-btn>
            <v-btn
                :disabled="!enableDelete"
                color="green darken-1 font-weight-bold"
                text
                @click="deleteUser"
            >
              Želim
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-container>
</template>

<script>
import {usersPageHeaders, masterUsersPageHeaders} from '@/mixins/data-table-headers'
import {fieldRules} from "@/mixins/field-rules";
import {defaultUser} from "@/mixins/default-items";

export default {
  name: 'Users',
  components: {},
  data: () => ({
    searchString: '',
    dialog: false,
    headers: [],
    users: [],
    roles: [],
    formTitle: 'Novi unos',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    deletedItem: {},
    editedIndex: -1,
    fieldRules: null,
    enableSave: true,
    enableDelete: true,
    deleteUserModal: false,
    userName: '',
    companies: [],
    loggedUserRoleId: null
  }),
  created() {
    this.fieldRules = fieldRules
    this.editedItem = Object.assign({}, defaultUser)
    this.loadAllUsers();
    this.loggedUserRoleId = parseInt(this.$store.getters['auth/user'].role_id)
    this.loadRoles()

    if (this.loggedUserRoleId === 1) {
      this.headers = masterUsersPageHeaders
      this.loadAllCompanies()
    }
    else{
      this.headers = usersPageHeaders
    }

  },
  methods: {
    async loadAllUsers() {
      await this.$store.dispatch('users/getAllUsers').then((res) => {
        this.users = res.data
      });
    },
    async loadRoles() {
      await this.$store.dispatch('roles/getAllRoles').then((res) => {
        this.roles = res.data
      });
    },
    async loadAllCompanies() {
      await this.$store.dispatch('companies/getAllCompanies').then((res) => {
        this.companies = res.data
      });
    },

    editItem(item) {
      this.editedItem = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.role_id = parseInt(this.editedItem.role_id)
      this.editedItem.company_id = parseInt(this.editedItem.company_id)
      this.formTitle = item.name
      this.dialog = true
    },

    deleteItem(item) {
      this.deletedItem = this.users.indexOf(item)
      this.deletedItem = Object.assign({}, item)
      this.formTitle = item.name
      this.dialog = true
    },

    validateForm() {
      if (this.$refs.form.validate())
        this.save()
    },

    async save() {
      this.enableSave = false

      await this.$store.dispatch('users/saveUser', this.editedItem).then((res) => {
        if (res.success) {
          this.loadAllUsers();
          this.$store.dispatch('showSnackbar', {text: 'Uspješno sačuvano', color: 'green'});
          this.close()
        } else {
          this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$store.dispatch('errorMessages/errorMapper', error.response.data.message).then(
              e =>  this.$store.dispatch('showSnackbar', {text: e, color: 'red'})
          )
        } else {
          this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
        }
      }).finally(() => {
        this.enableSave = true
        this.formTitle = "Novi unos"
      })
    },

    async deleteUser() {
      this.enableDelete = false

      await this.$store.dispatch('users/deleteUser', this.editedItemDeleted).then((res) => {
        if (res.success) {
          this.loadAllUsers();
          this.$store.dispatch('showSnackbar', {text: 'Korisnik uspešno izbrisan', color: 'green'});
          this.closeDeleteUserModal()
        } else {
          this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
        }
      }).catch(() => {
        this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
      }).finally(() => {
        this.enableDelete = true
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultUser)
        this.editedIndex = -1
      })
      this.$refs.form.resetValidation()
      this.formTitle = "Novi unos"
    },

    showDeleteUserModal(item) {
      this.editedItemDeleted = item
      this.userName = item.name
      this.deleteUserModal = true
    },

    closeDeleteUserModal() {
      this.deleteUserModal = false
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
}
</script>
